<template>
  <div class="bg-white px-2 rounded-xl flex flex-row justify-center items-center text-new-black text-arial">
    <input v-model="search" type="text" placeholder="Suche" class="w-full text-lg px-2 placeholder-new-black bg-transparent border-none appearance-none outline-none" />
    <glassesSVG />
  </div>
</template>

<script>
// SVG
import glassesSVG from '../../../assets/svg/glasses.vue';

export default {
  name: 'searchBar',
  components: { glassesSVG },

  data() {
    return {
      search: '',
    };
  },

  watch: {
    search() {
      this.$store.commit('setSearchString', this.search);
    },
  },
};
</script>
