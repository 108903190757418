<template>
<transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
  <div v-if="showOverlay === 'coursePicker'" class="w-full h-auto md:h-full fixed bottom-0 md:inset-0 z-30 flex flex-row justify-center items-center pointer-events-none text-new-black font-arial">
    <div class="w-full md:w-auto md:min-w-1/3 h-auto md:h-auto bg-new-yellow rounded-t-3xl md:rounded-3xl p-5 pb-10 md:p-12 pointer-events-auto">
      <div class="md:hidden text-xl cursor-pointer" @click="hideCoursePicker">schließen</div>
      <div class="mt-2 md:mt-0 font-faro font-bold text-3xl">Kursauswahl</div>

      <div v-if="kurse" class="w-full bg-white rounded-xl shadow-new mt-5 border border-hi-lighgrey overflow-hidden">
        <div v-for="(kurs, index) in kurse" :key="kurs">
          <selector :label=kurs.attributes.Bezeichnung
            storeContainer='settings'
            storeID='currentCourse'
            storeID2='currentCourseColor'
            storeID3='currentCourseIndex'
            storeID4='currentCourseID'
            storeID5='currentCourseGlossaryID'
            storeID6='currentCourseColor2'
            storeID7='currentCourseColor3'
            :value2=kurs.attributes.Farbcode
            :value3=index
            :value4=kurs.id
            :value5=kurs.attributes?.glossar?.data?.id
            :value6=kurs.attributes.Farbcode2
            :value7=kurs.attributes.Farbcode3
            type='replace'
            :border="index < kurse.length -1 ? true : false" />
        </div>
      </div>

    </div>
  </div>
</transition>
</template>

<script>

// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// Components
import selector from '../../../symbols/selector.vue';

export default {
  name: 'coursePicker',
  mixins: [slideInOutBottom, iosVibrations],
  components: { selector },

  computed: {
    showOverlay() {
      return this.$store.state.layout.showOverlay;
    },

    me() {
      return this.$store.state.profile.me;
    },
    kurse() {
      const kurse = [];
      const kursIds = [];
      this.me.klassen.data.forEach((klasse) => {
        const index = klasse.attributes.Zuordnung.findIndex((zuordnung) => zuordnung.Kurs);
        const kurs = klasse.attributes.Zuordnung[index].Kurs.data;
        const kursId = klasse.attributes.Zuordnung[index].Kurs.data.id;
        if (!kursIds.includes(kursId)) {
          kursIds.push(kursId);
          kurse.push(kurs);
        }
      });
      return kurse;
    },
  },

  methods: {
    hideCoursePicker() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', null);
    },
  },
};
</script>
