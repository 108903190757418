<template>
  <!-- Components -->
  <darkBackground />
  <coursePicker />

  <!-- TOP BAR -->
  <div v-if="$route.meta.showNav && $route.meta.navType !== 'clean'"
    class="transition duration-300 ease-in-out fixed top-0 w-full py-5 md:pl-24 flex flex-row justify-between items-center font-arial text-new-black z-10 "
    :class="{'pt-safe' : parseInt(sat) !== 0, 'transition duration-300 ease-in-out border-hi-lighgrey' : scrollDown, 'bg-new-yellow md:bg-transparent border-new-yellow md:border-0 border-b-2' : !$route.meta.transparentNav}">

    <div class="flex flex-row justify-start items-center md:px-5">
      <div class="md:hidden w-10 h-10 ml-5 cursor-pointer bg-center bg-no-repeat bg-cover" :style="{ backgroundImage: 'url(' + require('../../assets/images/logo.png') + ')' }"  @click="backHome" ></div>
      <!-- Course -->
      <div class="px-5">
        <div class="bg-hi-purple px-2 py-0.5 rounded-lg flex flex-row justify-center items-center text-white" @click="openCoursePicker">
          <div class="text-lg">{{currentCourse}}</div>
          <chevronDown v-if="kurse.length > 1" class="ml-2" />
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-start items-center md:px-5">

      <!-- Notifications -->
      <router-link :to="{ name: 'notifications' }">
        <bellSVG class="mr-5"/>
      </router-link>

      <div class="flex flex-row">
        <!-- Search -->
        <searchBar v-if="$route.meta.searchBar" class="hidden md:flex flex-shrink-0 mr-3"/>

        <!-- Profile -->
        <router-link :to="{ name: 'profile' }"  class="relative w-10 h-10 rounded-full bg-white mr-5 flex flex-row justify-center items-center border-2 border-hi-lightgrey overflow-hidden" @click="iosVibrate(0)">
          <div v-if="myProfileImage" class="w-full h-full bg-center bg-cover bg-no-repeat" :style="{ backgroundImage: 'url(' + myProfileImage + ')' }"></div>
          <profile v-else />
        </router-link>
      </div>
    </div>

  </div>

  <!-- LEFT / BOTTOM BAR -->
  <div v-if="$route.meta.showNav" class="fixed z-10 bottom-0 md:left-0 md:top-0 bg-white w-full md:w-24 h-auto pb-8  md:h-screen shadow-top md:shadow-new flex flex-row md:flex-col justify-center items-center font-arial"
    :class="{'hidden md:flex' : $route.meta.navType === 'clean'}">
    <div  v-if="$route.meta.navType !== 'clean'" class="hidden md:block absolute top-4 w-14 h-14 p-2 cursor-pointe bg-center bg-no-repeat bg-cover" :style="{ backgroundImage: 'url(' + require('../../assets/images/logo.png') + ')' }" @click="backHome"></div>

    <div v-if="$route.meta.navType !== 'clean'" class="w-full flex flex-row md:block justify-around items-center px-5 md:px-0 mt-3">
      <!-- Learn -->
      <router-link :to="{ name: 'learning' }" class="flex flex-col justify-center items-center md:my-12 cursor-pointer select-none outline-none" @click="iosVibrate(0)">
        <div class="transition duration-300 ease-in-out border-new-black w-12 h-12 flex flex-row justify-center items-center rounded-lg">
          <faecher class="w-8 h-8" :color="$route.name === 'learning' ? 'black' : 'grey' " />
        </div>
      </router-link>

      <!-- Practice -->
      <router-link :to="{ name: 'training' }" class="flex flex-col justify-center items-center md:my-12" @click="iosVibrate(0)">
        <div class="transition duration-300 ease-in-out border-new-black w-12 h-12 flex flex-row justify-center items-center rounded-lg">
          <pen class="w-7 h-7" :color="$route.name === 'training' ? 'black' : 'grey' " />
        </div>
      </router-link>

      <!-- Test -->
      <router-link :to="{ name: 'exam' }" class="flex flex-col justify-center items-center md:my-12" @click="iosVibrate(0)">
        <div class="transition duration-300 ease-in-out border-new-black w-12 h-12 flex flex-row justify-center items-center rounded-lg">
          <test class="w-8 h-8" :color="$route.name === 'exam' ? 'black' : 'grey' " />
        </div>
      </router-link>

      <!-- Glossar -->
      <router-link :to="{ name: 'glossary' }" class="flex flex-col justify-center items-center md:my-12" @click="iosVibrate(0)">
        <div class="transition duration-300 ease-in-out border-new-black w-12 h-12 flex flex-row justify-center items-center rounded-lg">
          <glossarySVG class="w-8 h-8" :color="$route.name === 'glossary' ? 'black' : 'grey' " />
        </div>
      </router-link>

    </div>

    <!-- Logout -->
    <div v-if="$route.meta.navType !== 'clean'" class="hidden md:block  absolute bottom-6 cursor-pointer" @click="logout">
      <logout />
    </div>

  </div>
</template>

<script>
// Mixins
import detectNotch from '../../mixins/detectNotch.vue';
import iosVibrations from '../../mixins/iosVibrations.vue';
import colors from '../../mixins/colors.vue';

// SVG
import faecher from '../../assets/svg/faecher.vue';
import pen from '../../assets/svg/pen.vue';
import test from '../../assets/svg/test.vue';
import glossarySVG from '../../assets/svg/glossary.vue';
import profile from '../../assets/svg/profile.vue';
import logout from '../../assets/svg/logout.vue';
import chevronDown from '../../assets/svg/chevronDown.vue';
import bellSVG from '../../assets/svg/bell.vue';

// Components
import coursePicker from './components/coursePicker.vue';
import darkBackground from '../../symbols/darkBackground.vue';
import searchBar from './components/searchBar.vue';

export default {
  name: 'navbar',
  mixins: [detectNotch, iosVibrations, colors],
  components: {
    faecher, pen, test, chevronDown, profile, logout, coursePicker, darkBackground, glossarySVG, searchBar, bellSVG,
  },

  computed: {
    scrollDown() {
      return this.$store.state.layout.scrollDown;
    },
    me() {
      return this.$store.state.profile.me;
    },
    myProfileImage() {
      return this?.me?.userData?.data?.attributes?.avatar?.data?.attributes?.url;
    },
    currentCourse() {
      return this.$store.state.settings.currentCourse;
    },
    currentCourseId() {
      return this.$store.state.settings.currentCourseID;
    },
    currentCourseColor() {
      return this.$store.state.settings.currentCourseColor;
    },
    queryServerTimeEx() {
      return this.$store.state.queryServerTimeEx;
    },
    filterClassId() {
      return this.$store.state.settings.filterClassId;
    },
    kurse() {
      const kurse = [];
      const kursIds = [];
      this.me.klassen.data.forEach((klasse) => {
        if (klasse.attributes?.publishedAt) {
          const index = klasse.attributes.Zuordnung.findIndex((zuordnung) => zuordnung.Kurs);
          const kurs = klasse.attributes.Zuordnung[index].Kurs.data;
          const kursId = klasse.attributes.Zuordnung[index].Kurs.data.id;
          if (!kursIds.includes(kursId)) {
            kursIds.push(kursId);
            kurse.push(kurs);
          }
        }
      });
      return kurse;
    },
  },

  watch: {
    currentCourse() {
      this.$store.commit('resetLernpfad');
    },
    currentCourseId() {
      this.$store.commit('setCurrentSemester', 0);
      this.getNewCurrentSemester();
    },
  },

  methods: {

    getNewCurrentSemester() {
      this.queryServerTimeEx()
        .then((result) => {
          if (result.error) {
            console.log(result.error.message);
          } else {
            const serverTime = Date.parse(result.data.getServerTime.serverTime);
            const classIndex = this.me.klassen?.data.findIndex((klasse) => klasse.id === this.filterClassId);
            console.log(classIndex);
            const semester = this.me.klassen?.data[classIndex]?.attributes?.Semester;
            if (semester) {
              semester.forEach((sem) => {
                const semesterStart = Date.parse(sem.Start);
                const semesterEnde = Date.parse(sem.Ende);
                if (serverTime >= semesterStart && serverTime <= semesterEnde && sem.Semester) this.$store.commit('setCurrentSemester', sem.Semester);
              });
            }
          }
        });
    },

    openCoursePicker() {
      if (this.me?.klassen?.data.length > 1) {
        this.iosVibrate(0);
        this.$store.commit('showOverlay', 'coursePicker');
      }
    },
    backHome() {
      this.iosVibrate(0);
      this.$router.push({ name: 'dashboard' });
    },

    logout() {
      this.$store.dispatch('logoutUser');
    },
  },
};

</script>

<style>
html
{
  -webkit-tap-highlight-color:transparent;
}
</style>
