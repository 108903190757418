<template>
  <navbar class="font-faro" />
  <router-view v-slot="{ Component }">
    <transition @enter="fadeIn" @leave="fadeOut" :css="false">
      <component :is="Component" class="font-faro" />
    </transition>
  </router-view>
  <updateUserSessionMutation />
  <queryMyBattles />
  <queryDataPolicy />
  <updateActiveCoursesMutation />
  <updateUserDataRecentTrainingMutation />
  <updateUserDataPolicyMutation />
  <updateUserDataWrongPlayedQuestionsMutation />
  <updateUserDataRecentPlayedQuestionsMutation />
  <updateUserDataWrongPlayedExercisesMutation />
  <updateUserDataRecentPlayedExercisesMutation />
  <updateUserDataCompleteOnboardingMutation />
  <updateUserDataLernpfadProgressMutation />
  <updateUserDataFirstLoginMutation />
  <updateCurrentSocketMutation />
  <updateUserDataFcmMutation />
  <updateUserDataListMutation />
  <validateAnswersMutation />
  <updatePointsMutation />
  <updateLastLernpfadMutation />
  <queryUserSession />
  <queryMe />
  <queryMyExams />
  <queryUserId />
  <resetPasswordMutation />
  <queryVortestId />
  <queryServerTime />
  <querySelectedLernpfad />
</template>

<script>
// Components
import queryDataPolicy from './modules/login/strapi/queryDataPolicy.vue';
import querySelectedLernpfad from './modules/learning/strapi/querySelectedLernpfad.vue';
import updatePointsMutation from './modules/ranking/strapi/updatePointsMutation.vue';
import updateUserSessionMutation from './modules/login/strapi/updateUserSessionMutation.vue';
import updateUserDataCompleteOnboardingMutation from './modules/login/strapi/updateUserDataCompleteOnboardingMutation.vue';
import updateActiveCoursesMutation from './modules/login/strapi/updateActiveCoursesMutation.vue';
import updateUserDataListMutation from './modules/training/strapi/mutations/updateUserDataListMutation.vue';
import updateUserDataWrongPlayedQuestionsMutation from './modules/training/strapi/mutations/updateUserDataWrongPlayedQuestionsMutation.vue';
import updateUserDataRecentPlayedQuestionsMutation from './modules/training/strapi/mutations/updateUserDataRecentPlayedQuestionsMutation.vue';
import updateUserDataWrongPlayedExercisesMutation from './modules/training/strapi/mutations/updateUserDataWrongPlayedExercisesMutation.vue';
import updateUserDataRecentPlayedExercisesMutation from './modules/training/strapi/mutations/updateUserDataRecentPlayedExercisesMutation.vue';
import updateUserDataLernpfadProgressMutation from './modules/learning/strapi/updateUserDataLernpfadProgressMutation.vue';
import validateAnswersMutation from './modules/training/strapi/mutations/validateAnswersMutation.vue';
import updateUserDataFirstLoginMutation from './modules/login/strapi/updateUserDataFirstLoginMutation.vue';
import updateUserDataRecentTrainingMutation from './modules/training/strapi/mutations/updateUserDataRecentTrainingMutation.vue';
import resetPasswordMutation from './modules/login/strapi/resetPasswordMutation.vue';
import updateUserDataPolicyMutation from './modules/login/strapi/updateUserDataPolicyMutation.vue';
import updateUserDataFcmMutation from './modules/login/strapi/updateUserDataFcmMutation.vue';
import queryUserSession from './modules/login/strapi/queryUserSession.vue';
import queryMe from './modules/profile/strapi/queryMe.vue';
import queryMyBattles from './modules/profile/strapi/queryMyBattles.vue';
import queryUserId from './modules/login/strapi/queryUserId.vue';
import navbar from './modules/navbar/navbar.vue';
import queryMyExams from './modules/exam/strapi/queryMyExams.vue';
import queryVortestId from './modules/exam/strapi/queryVortestId.vue';
import queryServerTime from './modules/exam/strapi/queryServerTime.vue';
import updateLastLernpfadMutation from './modules/learning/strapi/updateLastLernpfadMutation.vue';
import updateCurrentSocketMutation from './modules/login/strapi/updateCurrentSocketMutation.vue';


// Mixins
import auth from './mixins/auth.vue';
import fcmMixin from './mixins/fcm.vue';
import sockets from './mixins/sockets.vue';
import fadeInOut from './mixins/animations/fadeInOut.vue';

export default {
  name: 'app',
  components: {
    navbar,
    queryDataPolicy,
    updateUserSessionMutation,
    updateCurrentSocketMutation,
    updatePointsMutation,
    updateUserDataPolicyMutation,
    querySelectedLernpfad,
    updateUserDataCompleteOnboardingMutation,
    updateActiveCoursesMutation,
    updateUserDataWrongPlayedExercisesMutation,
    updateUserDataRecentPlayedExercisesMutation,
    updateUserDataFirstLoginMutation,
    updateUserDataLernpfadProgressMutation,
    validateAnswersMutation,
    updateUserDataFcmMutation,
    updateUserDataRecentPlayedQuestionsMutation,
    updateUserDataWrongPlayedQuestionsMutation,
    queryUserSession,
    queryMe,
    queryMyBattles,
    queryUserId,
    resetPasswordMutation,
    updateUserDataRecentTrainingMutation,
    updateUserDataListMutation,
    queryMyExams,
    queryVortestId,
    queryServerTime,
    updateLastLernpfadMutation,
  },
  mixins: [auth, fcmMixin, sockets, fadeInOut],

  computed: {
    scrollDown() {
      return this.$store.state.layout.scrollDown;
    },
    currentCourseId() {
      return this.$store.state.settings.currentCourseID;
    },
    me() {
      return this.$store.state.profile.me;
    },
    classId() {
      let classId = null;
      if (this?.me?.klassen?.data) {
        this.me.klassen.data.forEach((klasse) => {
          if (klasse.attributes?.publishedAt) {
            const index = klasse.attributes.Zuordnung.findIndex((zuordnung) => zuordnung.Kurs);
            if (klasse.attributes.Zuordnung[index].Kurs.data.id === this.currentCourseId) {
              classId = klasse.id;
            }
          }
        });
      }
      return classId;
    },
  },

  watch: {
    currentCourseId() {
      this.$store.commit('setClassId', this.classId);
      this.$store.commit('setFilterClassId', this.classId);
    },
  },

  mounted() {
    // const that = this;
    window.addEventListener('scroll', () => {
      if (window.scrollY > 34 && !this.scrollDown) {
        this.$store.commit('toggleScrollDown', true);
      } else if (window.scrollY < 34 && this.scrollDown) {
        this.$store.commit('toggleScrollDown', false);
      }
    });
  },
};
</script>

<style>
.scrollbar {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}

.scrollbar::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
.snap-mandatory {
  scroll-snap-type: x mandatory;
  scroll-padding: 1.25rem;
}
.snap-start {
  scroll-snap-align: start;
}
.snap-end {
  scroll-snap-align: end;
}
</style>
