<template>
  <svg class="w-8 h-8 text-hi-lightgrey" viewBox="0 0 30 35" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.95 18.8539C23.2067 18.8539 29.9 24.1778 29.9 26.9127C29.9 29.6476 23.2067 34.9715 14.95 34.9715C6.69334 34.9715 0 29.6476 0 26.9127C0 24.1778 6.69334 18.8539 14.95 18.8539ZM14.95 0C19.4032 0 23.0132 3.61002 23.0132 8.06321C23.0132 12.5164 19.4032 16.1264 14.95 16.1264C10.4968 16.1264 6.88679 12.5164 6.88679 8.06321C6.88679 3.61002 10.4968 0 14.95 0Z"/>
    </svg>
</template>

<script>
export default {
  name: 'profileSVG',
};
</script>
